import React, { Component } from 'react';
import "./Banner.css"
import banner from "../../resources/images/IMG_7782__one.jpeg"
class Banner extends Component {
    state = {}
    render() {
        return (
            <section className="banner">
                <div className="banner-holder">
                    <img src={banner} className="banner-image" alt="Banner" />
                    <div className="banner-cover">
                        <div className="banner-texts">
                            {/* <p>
                                Driving S4DI3
                             </p>
                            <p>
                                
                            </p> */}
                            {/* <div className="btn-midd">
                                <div className="btn-midd gradianet">
                                    <p>View Tour Dates</p>
                                    <i className=" icon-fast-fw-outline" />
                                </div>
                            </div> */}
                        </div>

                    </div>
                </div>
                <div className="theStory-holder">
                    <div className="theStory-textframe">
                        <p>
                            Driving S4DI3 <br />
                            is a genre bending group<br />
                            that fuses jazz oral traditions<br />
                            with an industrial tone.
                        </p>

                    </div>
                </div>

            </section>
        );
    }
}
export default Banner;